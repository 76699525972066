import React, { useEffect, useState, useMemo, useRef } from 'react';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import utils from '../../utils';
import { Link } from 'react-router-dom';
import useAction from '../../store/actions';
import Tooltip from '../Tooltip/Tooltip';
import {default as MuiTooltip} from '@mui/material/Tooltip';

/**
 * @export
 * @param {Object} options
 * @param {String} label
 * @param {Boolean} readonly
 * @param {String} value
 * @param {Boolean} required
 * @param {Function} onChange
 * @param {String} error
 * @param {String} fieldName
 * @param {String} defaultValue
 * @param {Object} fieldData
 * @param {String} className
 * @param {String} info
 *
 * @return {JSX}
 */
export default function Select({
	options,
	label,
	readonly,
	value,
	required,
	onChange,
	error,
	fieldName,
	defaultValue,
	fieldData,
	className,
	info,
	open,
}) {
	const sAction = useAction();
	const [selectOpen, setSelectOpen] = useState(!!open);
	const [localValue, setLocalValue] = useState(value || '');
	const [searchValue, setSearchValue] = useState('');
	const debugMode = localStorage.getItem('debugMode') ? true : false;
	const textFieldRef = useRef(null);

	useEffect(() => {
		setLocalValue(value);
	}, [value]);

	useEffect(() => {
		if (selectOpen && textFieldRef.current) {
			textFieldRef.current.focus();
		}
	}, [selectOpen, searchValue]);

	/***/
	const handleRedirect = () => {
		sAction.clearModuleData();
	};

	/**
	 * @param {Event} e
	 */
	const handleChange = (e) => {
		setLocalValue(e.target.value);
		onChange(e);
	};

	const memoizedRenderItems = useMemo(() => {
		if (!options) {
			return;
		}
		const filteredOptions = Object?.keys(options)?.filter(key =>
			options[key]?.toLowerCase()?.includes(searchValue.toLowerCase())
		);
		return filteredOptions.map((key) => (
			<MenuItem className="selectField__item" key={key} value={key}>
				<div>{options[key]}{debugMode ? <span> [{key}]</span> : null}</div>
			</MenuItem>
		));
	}, [options, searchValue]);

	/**
	 * @param {Event} e
	 */
	const handleClose = (e) => {
		if (!e.target.classList.contains('selectField__search') && !e.target.classList.contains('MuiInputBase-input')) {
			setSelectOpen(false);
			setSearchValue('');
		}
	};

	/**
	 * @param {Event} e
	 */
	const handleOpen = (e) => {
		if (!e.target.classList.contains('ListViewLink__link')) {
			setSelectOpen(true);
		}
	};

	const searchTranslate = utils.translate('LBL_SEARCH');

	return (
		<FormControl fullWidth className={`selectField ${className}`}>
			{label && (
				<label className="detailField__label">
					{label}
					{debugMode ? <span className="debugName"> {fieldName}</span> : null}
					{info ?
						<Tooltip placement="top" title={info}>
							<span className="fieldIcon iconfas-info"/>
						</Tooltip> : null}
					{required ? <span className="detailField__required">*</span> : null}
				</label>
			)}
			<MuiSelect
				className={readonly ? 'selectField__readonly' : ''}
				error={!!error}
				required={required}
				onChange={(e) => handleChange(e)}
				value={options[localValue] ? localValue || defaultValue || '' : ''}
				name={fieldName}
				open={selectOpen}
				onOpen={(e) => handleOpen(e)}
				onClose={(e) => handleClose(e)}
				inputProps={{
					readOnly: readonly,
				}}
				renderValue={(localValue) => {
					if (fieldData?.fieldType === 'relateSelect' && localValue != 0 && value) {
						return (
							<Link className="ListViewLink__link" onClick={() => handleRedirect()} to={`/${utils.getUrlPrefix()}/detail/${fieldData.module}/${localValue}`}>
								<span className={`selectField__icon iconfas-${fieldData.module}`}></span>
								{options[localValue]}
							</Link>
						);
					} else {
						return <div className="selectField__value">{options[localValue]}</div>;
					}
				}}
			>
				{Object.keys(options).length >= 10 ?
					<div className="selectField__search">
						<TextField
							fullWidth
							placeholder={searchTranslate}
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
							variant="outlined"
							size="small"
							onKeyDown={(e) => e.stopPropagation()}
							inputRef={textFieldRef}
						/>
					</div>
					: null}
				{memoizedRenderItems}
			</MuiSelect>
			{error ? (
				<MuiTooltip arrow title={<p className="detailToolTip">{error}</p>} placement="top">
					<span className="iconfas-warning inputFieldBox__error-icon"></span>
				</MuiTooltip>
			) : null}
		</FormControl>
	);
}
