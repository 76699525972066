import React from 'react';
import ApexCharts from 'react-apexcharts';

/**
 *
 * @export
 * @param {Array} data
 * @param {Array} labels
 * @param {Array} colors
 *
 * @return {JSX}
 */
export default function ChartDonut({data, labels, colors}) {
	let options = {
		labels: labels,
		dataLabels: {enabled: false},
		tooltip: {enabled: true, fillSeriesColor: false, marker: {show: false}},
		legend: {show: false},
		fill: {colors: colors, opacity: 1}, //, type: 'gradient'
		chart: {
			type: 'donut',
		},
		plotOptions: {
			pie: {
				donut: {size: '55%'},
				expandOnClick: false,
				customScale: 1.0,
			},
		},
		states: {
			normal: {
				filter: {
					type: 'none',
					value: 0,
				},
			},
			hover: {
				filter: {
					type: 'none',
					value: 1,
				},
			},
			active: {
				allowMultipleDataPointsSelection: false,
				filter: {
					type: 'none',
					value: 1,
				},
			},
		},
	};

	return <ApexCharts series={data} options={options} type="donut" width="100%" />;
}
