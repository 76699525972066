import React, { useRef, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import allLocales from '@fullcalendar/core/locales-all';
import CalendarEventList from './CalendarEventList';
import utils from '../../utils';

/**
 * @export
 * @param {Array} events list of events
 * @param {Date} date
 * @param {String} locale language
 * @param {String} calType month, week or day
 * @param {Function} getCalendar
 *
 * @return {JSX}
 */
export default function CalendarList({ events, date, locale, calType, getCalendar }) {
	const [listEvents, setListEvents] = useState([]);
	const calendarRef = useRef();

	useEffect(() => {
		filterEvents(events);
	}, [events, date, calType]); // Also add calType as a dependency

	/**
	 * Renders content of the event
	 *
	 * @param {Object} info
 	 *
 	 * @return {JSX}
 	 */
	const eventContent = (info) => {
		return <CalendarEventList date={date} calType={calType} getCalendar={getCalendar} info={info} />;
	};

	/**
	 * @param {Object} eventInfo
 	 *
 	 * @return {Array}
 	 */
	const getEventClassNames = (eventInfo) => {
		const eventDate = new Date(eventInfo.event.end);
		const now = new Date();

		if (eventDate < now) {
			return ['old-event'];
		}

		return [];
	};

	/**
	 * @param {Array} eventsToFilter
 	 *
 	 * @return {void}
 	 */
	const filterEvents = (eventsToFilter) => {
		let filteredEvents = [];
		if (calType === 'month') {
			filteredEvents = eventsToFilter;
		} else if (calType === 'week') {
			eventsToFilter?.forEach((event) => {
				if (utils.isDateInCurrentWeek(event.start) || utils.isDateInCurrentWeek(event.end)) {
					filteredEvents.push(event);
				}
			});
		} else if (calType === 'day') {
			eventsToFilter?.forEach((event) => {
				if (utils.isDateInDay(event.start, date) || utils.isDateInDay(event.end, date)) {
					filteredEvents.push(event);
				}
			});
		}

		setListEvents(filteredEvents); // Removed return statement
	};

	return (
		<div className="calendarList">
			<FullCalendar
				ref={calendarRef}
				plugins={[listPlugin]}
				initialView="listMonth"
				events={listEvents}
				height={'100%'}
				headerToolbar={false}
				initialDate={date}
				locales={allLocales}
				locale={locale}
				eventContent={eventContent}
				editable={true}
				eventDisplay="block"
				eventClassNames={getEventClassNames}
			/>
		</div>
	);
}