import React from 'react';

/**
 * @export
 * @param {HTML} value
 * @param {String} className
 * @param {Object} style
 *
 * @return {JSX}
 */
export default function ListViewTextHtml({value, className, style}) {
	return (
		<td
			dangerouslySetInnerHTML={{__html: value}}
			className={`${className} listViewNumber`}
			style={style}
		/>
	);
}
