/**
 * @export
 * @param {String} url
 * @return {Function}
 */
export default function goUrl(url) {
	return () => {
		window.open(url, '_blank');
	};
}
