/**
 * @export
 * @param {Array} array
 * @param {Number} index
 * @param {*} item
 *
 * @return {Array}
 */
export default function insertAtIndex(array, index, item) {
	const newArray = [...array]; // Create a shallow copy of the array
	if (index >= 0 && index <= newArray.length) {
		newArray.splice(index, 0, item);
	} else {
		console.error('Index out of bounds error [E01001]');
	}
	return newArray;
}
