import React from 'react';

export default function ErrorPage({
	error,
	firstMessage,
	secondMessage,
	link,
}) {
	return (
		<div className="errorPage">
			<div className="errorPage__content">
				<img className="errorPage__img" src="/images/error.png"/>
				<div className="errorPage__box">
					<div className="errorPage__text">
						<div>{firstMessage}</div>
						<div>{secondMessage}</div>
					</div>
					{error?.message ? <div className="errorPage__message">Message: {error?.message}</div> : null}
					<a className="errorPage__goBack" href={link ? link : window.location.origin}>Go back to app</a>
				</div>
			</div>
		</div>
	);
}
