import React, {useState} from 'react';
import ListViewField from '../../../ListView/ListViewField';
import Popover from '../../../Popover/Popover';

/**
 * @export
 * @param {Object} row
 * @param {Array} columns
 * @param {String} relatedModule
 * @param {Function} deleteRelation
 * @param {Function} deleteRecord
 * @param {Number | String} parentId
 * @param {Number | String} variantId
 * @param {Array} disableRowActions
 * @param {String} subpanelKey
 *
 * @return {JSX}
 */
export default function SubpanelTableRow({
	row,
	columns,
	relatedModule,
	deleteRelation,
	deleteRecord,
	parentId,
	variantId,
	disableRowActions,
	prefix,
	subpanelKey,
}) {
	const [anchor, setAnchor] = useState(null);
	const [open, setOpen] = useState(false);

	/**
	 * @return {JSX}
	 */
	const renderFields = () => {
		return columns.map((column) => {
			return <ListViewField
				headerColumn={column}
				key={`${row.id}_${column.columnName}_${relatedModule}`}
				fieldData={row[column.columnName]}
				rowId={row.id}
				module={relatedModule}
				name={column.columnName}
				recordName={row?.name}
				parentId={parentId}
				variantId={variantId}
				prefix={`${prefix}/detail/subpanels/${subpanelKey}`}
			/>;
		});
	};

	/**
	 * @return {Array}
	 */
	const filterOptions = () => {
		const actions = [
			{name: 'deleteRelation', icon: 'deleteRelation', label: 'LBL_DELETE_RELATION', onClick: () => deleteRelation(row.id)},
			{name: 'deleteRecord', icon: 'delete', label: 'LBL_DELETE_RECORD', onClick: () => deleteRecord(row.id)},
		];

		const filteredActions = [];

		actions.forEach((action) => {
			if (!disableRowActions?.includes(action.name)) {
				filteredActions.push(action);
			}
		});

		return filteredActions;
	};

	const actions = filterOptions();

	/**
	 * @param {Event}
	 *
	 * @returns {void}
	 */
	const handleAdd = (e) => {
		setAnchor(e.currentTarget);
		setOpen(!open);
	};

	return (
		<tr className="subpanelTable__row">
			{renderFields()}
			<td className="subpanelTable__delete">
				{actions?.length > 0 ? <div className="subpanelTable__deleteIcon iconfas-dotsMenu" onClick={(e) => handleAdd(e)}>
					<Popover id={relatedModule} anchor={anchor} open={open} options={actions} />
				</div> : null}
			</td>
		</tr>
	);
}
