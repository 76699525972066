import React, { useContext, useState } from 'react';
import utils from '../../../utils';
import Input from '../../FormElements/Input';
import { ConfigContext } from '../../../contexts/ConfigContext';

/**
 * @export
 * @return {JSX}
 */
export default function CreditCheck() {
	const [seePassword, setSeePassword] = useState(false);

	const {
		state,
		setValue,
	} = useContext(ConfigContext);

	return (
		<div className="configCreditCheck">
			<img className="configCreditCheck__logo" src="/images/creditcheck_logo.PNG"/>
			<div className="config__label">{utils.translate('LBL_CREDIT_CHECK_LOGIN_DESC')}</div>
			<div className="config__label linkText"
				onClick={() => window.open('https://www.creditcheck.cz/FormRegistrationWS.aspx', '_blank')}>
				{utils.translate('LBL_CREDIT_CHECK_REGISTER')}
			</div>
			<div className="config__form">
				<Input
					label={utils.translate('LBL_USERNAME')}
					value={state.creditCheckUsername}
					onBlur={(e) => setValue('creditCheckUsername', e.target.value)}
				/>
				<Input
					label={utils.translate('LBL_PASSWORD')}
					value={state.creditCheckPassword}
					onBlur={(e) => setValue('creditCheckPassword', e.target.value)}
					type={seePassword ? 'text' : 'password'}
				/>
				<span
					className="config__showPassword iconfas-eye"
					onMouseDown={() => setSeePassword(true)}
					onMouseUp={() => setSeePassword(false)}
				/>
			</div>
			<div className="config__label">{utils.translate('LBL_MORE_INFO')}</div>
			<ul className="configCreditCheck__list">
				<li className="config__label linkText"
					onClick={() => window.open('https://www.creditcheck.cz/SemaforVysvetleni.aspx', '_blank')}>
					{utils.translate('LBL_COMPANY_SEMAPHORE')} <span className="configCreditCheck__list-icon iconfas-semaphore"/>
				</li>
				<li className="config__label linkText"
					onClick={() => window.open('https://www.creditcheck.cz/form-cenik', '_blank')}>
					{utils.translate('LBL_PRICE_LIST')} <span className="configCreditCheck__list-icon iconfas-money"/>
				</li>
				<li className="config__label linkText"
					onClick={() => window.open('https://www.creditcheck.cz/zdroje', '_blank')}>
					{utils.translate('LBL_CREDIT_CHECK_RESOURCES')} <span className="configCreditCheck__list-icon iconfas-book"/>
				</li>
			</ul>
		</div>
	);
}
