/**
 * @export
 * @param {Object} state
 * @param {String} action
 * @return {Object}
 */
export default function showInfoBanner(state, action) {
	return { ...state, config: {
		...state.config,
		infoBanner: {
			...state.config?.infoBanner,
			message: action.payload.message,
			icon: action.payload.icon,
			type: action.payload.type,
		}
	}
	};
}