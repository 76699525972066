/**
 * @export
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function confirmPopup(state, action) {
	const newItem = {
		name: 'ConfirmPopup',
		header: 'LBL_CONFIRM_POPUP_HEADER',
		callback: action.payload.callback,
		closeCallback: action.payload.closeCallback,
		message: action.payload.message,
		id: (Math.random() * 1000000),
	};

	return {...state, popups: [...state.popups, newItem]};
}
