/**
 * @export
 * @param {String} message
 * @param {String} icon
 *
 * @return {Function}
 */
export default function showInfoBanner(message, icon, type) {
	return (dispatch) => {
		dispatch({
			type: 'SHOW_INFO_BANNER',
			payload: {message, icon, type},
		});
	};
}