import { useState, useEffect } from 'react';
import useAction from '../../store/actions';
import utils from '../../utils';

/**
 * @export
 */
export default function checkConnection() {
	const [isOnline, setIsOnline] = useState(navigator.onLine);
	const sAction = useAction();

	const offlineTranslate = utils.translate('LBL_NO_CONNECTION_WARNING');

	useEffect(() => {
		/***/
		const handleOnline = () => {
			sAction.hideInfoBanner();
			setIsOnline(true);
		};

		/***/
		const handleOffline = () => {
			sAction.showInfoBanner(offlineTranslate, 'wifi', 'warning');
			setIsOnline(false);
		};

		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);

		// Cleanup event listeners on component unmount
		return () => {
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
		};
	}, []);
}