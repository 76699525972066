import utils from '../../utils';
import useAction from '../../store/actions';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

/**
 * @export
 * @param {String | Number} recordId
 *
 * @return {Function}
 */
export default function copyQuote(recordId) {
	const sAction = useAction();
	const navigate = useNavigate();

	const errorLabel = utils.translate('LBL_ERROR');

	return () => {
		sAction.confirmPopup('LBL_CONFIRM_COPY_QUOTE_CONFIRM', () => {
			utils
				.post('copyQuote', {module: 'quote', recordId: recordId})
				.then((res) => {
					if (res !== 0) {
						navigate(`/${utils.getUrlPrefix()}/detail/quote/${res.data}`);
						sAction.clearModuleData();
					} else {
						toast.error(errorLabel);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		});
	};
}