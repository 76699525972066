/* eslint-disable no-undef */
import axios from 'axios';
import Cookies from 'js-cookie';
import getUrlPrefix from '../getUrlPrefix';
import validateUserAction from '../validation/validateUserAction';

/**
 * @export
 * @param {String} url
 * @param {Object} data
 * @param {Boolean} [auth=true]
 *
 * @return {Promise}
 */
export default function post(url, data, auth = true) {
	const backendUrl = process.env.REACT_APP_REST_URL;
	const clientCookieName = 'offerto' + '_' + getUrlPrefix();

	const modifiedData = Object.fromEntries(
		Object.entries(data).map(([key, value]) => {
			if (Array.isArray(value) && value.length === 0) {
				return [key, ['']];
			} else {
				return [key, value];
			}
		})
	);

	const token = Cookies.get(clientCookieName);

	const headers = {
		'Content-Type': 'multipart/form-data',
	};

	if (auth) {
		headers.Authorization = token ? `${token}` : '';
	}

	const timeout = 30000;

	return new Promise((resolve, reject) => {
		const timeoutPromise = new Promise((_, reject) => setTimeout(() => {

			reject(new Error('Request timed out'));
		}, timeout));

		const axiosPromise = axios({
			method: 'POST',
			withCredentials: true,
			data: modifiedData,
			headers: headers,
			baseURL: `${backendUrl}/index.php?org_url=${window.location.pathname}&act=${url}`,
		})
			.then((response) => {
				validateUserAction(response, url, clientCookieName);
				resolve(response.data);
			})
			.catch((error) => {
				validateUserAction(error.response, url, clientCookieName);
				reject(error);
			});

		Promise.race([axiosPromise, timeoutPromise]).catch(reject);
	});
}
