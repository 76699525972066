import { useNavigate } from 'react-router-dom';
import utils from '../../utils';

/**
 * @export
 * @param {String} module
 *
 * @return {Function}
 */
export default function cancelDetail(module) {
	const navigate = useNavigate();
	const urlPrefix = utils.getUrlPrefix();

	return () => {
		navigate(`/${urlPrefix}/list/${module}`);
	};
}
