import React from 'react';
import utils from '../../utils';

/**
 * @export
 * @param {String} message
 * @param {String} icon
 * @param {String} type
 *
 * @return {*}
 */
export default function infoBanner({message, icon, type}) {
	const offlineTranslate = utils.translate(message);

	return (
		<div className={`infoBanner infoBanner__${type}`}>
			<span className={`infoBanner__icon iconfas-${icon}`} />
			<span className="infoBanner__message">{offlineTranslate}</span>
		</div>
	);
}
