import React from 'react';

/**
 * @export
 * @param {Number | String} value
 * @param {String} className
 * @param {Object} style
 *
 * @return {JSX}
 */
export default function ListViewNumber({value, className, style}) {
	return (
		<td className={`${className} listViewNumber`} style={style}>
			{value}
		</td>
	);
}
