import utils from '../../utils';

/**
 * @export
 * @param {String} module
 * @param {String} buttonName
 *
 * @return {Function}
 */
export default function other(module, buttonName) {
	return () => {
		utils
			.post('other', {module: module, name: buttonName})
			.then((res) => {})
			.catch((error) => {
				console.error(error);
			});
	};
}
