import React, {useState, useEffect, useRef} from 'react';
import LeftMenuItem from './LeftMenuItem';

/**
 * Function that handles click outside of left menu
 *
 * @param {*} ref
 * @param {Boolean} open
 * @param {Function} setOpen
 */
function useClickOutside(ref, open, setOpen) {
	useEffect(() => {
		/**
		 * @param {Event} event
		 */
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target) && open) {
				setOpen(false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}

/**
 * @export
 * @param {Array} modules
 * @param {String} module
 * @param {Function} setOpen
 * @param {Boolean} open
 * @param {String} version
 *
 * @return {*}
 */
export default function LeftMenu({modules, module, setOpen, open, version}) {
	const wrapperRef = useRef(null);
	const colors = window.colorPalette;
	if (window.innerWidth <= 900) {
		useClickOutside(wrapperRef, open, setOpen);
	} else {
		// Just prevent errors
		useClickOutside(wrapperRef, false, setOpen);
	}

	useEffect(() => {
		const initOpen = localStorage.getItem('leftMenu');
		setOpen(JSON.parse(initOpen));
		if (window.innerWidth <= 700) {
			setOpen(false);
		}
	}, []);

	/**
	 * Function renders modules in left menu
	 *
	 * @return {JSX}
	 */
	const renderModules = () => {
		return modules.map((menuModule, i) => {
			const color = colors[i % colors.length];
			return (
				<LeftMenuItem
					fontColor={menuModule?.fontColor}
					color={color}
					leftMenuOpen={open}
					key={menuModule?.name}
					menuModule={menuModule}
					setMenuOpen={setOpen}
					module={module}
				/>
			);
		});
	};

	/**
	 * Handles opening of the menu
	 *
	 */
	const handleMenu = () => {
		setOpen(!open);
		localStorage.setItem('leftMenu', !open);
	};

	return (
		<div ref={wrapperRef} className={`leftMenu ${!open ? 'leftMenu__close' : ''}`}>
			<div className="leftMenu__logo">
				<div className="leftMenu__hamburger2" id="sidebarToggle" onClick={() => handleMenu()}>
					<div className="leftMenu__hamburger2__p1"></div>
					<div className="leftMenu__hamburger2__p2"></div>
					<div className="leftMenu__hamburger2__p3"></div>
				</div>
				<img className="leftMenu__logo-image" src="/images/logo2_light.png" alt="App logo" />
				<div className={`leftMenu__version${!open ? '__hidden' : ''}`}>{version}</div>
			</div>
			<div className="leftMenu__itemsBox">{renderModules()}</div>
		</div>
	);
}
