import React, { useEffect, useState } from 'react';
import ActionButtons from '../../components/ActionButtons/ActionButtons';
import utils from '../../utils';
import { Link } from 'react-router-dom';
import useAction from '../../store/actions';

/**
 * @export
 * @param {Object} config
 *
 * @return {JSX}
 */
export default function UsersTree({ config }) {
	const [userTree, setUserTree] = useState([]);
	const sAction = useAction();

	useEffect(() => {
		// Fetch the user tree data
		utils
			.post('getUsersTree', { module: 'users_tree' })
			.then((res) => {
				setUserTree(res.data);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	/**
 	 * @return {void}
 	 */
	const handleClick = (module) => {
		sAction.dsSet('view/module', module);
	};

	/**
	 * @param {Object} item
	 *
 	 * @return {JSX}
 	 */
	const renderItem = (item) => {
		const attributes = item.attributes;
		const routeModule = attributes.roleType == 10 ? 'user_client' : 'user';
		return (
			<div key={item.name} className={`usersTree__item usersTree__item-${attributes.roleType}`}>
				<Link
					className="usersTree__item-name"
					onClick={() => handleClick(routeModule)}
					to={`/${utils.getUrlPrefix()}/detail/${routeModule}/${attributes.id}`}
				>
					<span className="iconfas-user"/>{item.name} ({attributes.roleName})
				</Link>
				<div className="usersTree__item-box">
					{attributes.email &&
						<a className="usersTree__item-contact" href={'mailto:' + attributes.email}>
							<span className="iconfas-email"/>
							{attributes.email}
						</a>}
					{attributes.phone &&
						<a className="usersTree__item-contact" href={'tel:+' + attributes.phone}>
							<span className="iconfas-phone"/>
							{attributes.phone.replace('-', ' ')}
						</a>}
				</div>
				{item.children && item.children.length > 0 && (
					<div className="usersTree__children">
						{item.children.map((child) => renderItem(child))}
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			<div className="actionBox">
				<ActionButtons />
			</div>
			<div className="usersTree">
				<div className="usersTree__company">
					<span className="usersTree__company-name">
						{config?.systemSettings?.companyName}
					</span>
				</div>
				<div className="usersTree__content">
					{userTree.map((item) => renderItem(item))}
				</div>
			</div>
		</>
	);
}
