import React from 'react';
import { Link } from 'react-router-dom';
import utils from '../../utils';
import useAction from '../../store/actions';

/**
 * @export
 * @param {Object} data
 * @param {Function} setMenuOpen
 * @param {String} module
 * @param {Number} index
 * @param {Boolean} leftMenuOpen
 * @param {String} fontColor
 *
 * @return {*}
 */
export default function SubMenuItem({
	data,
	setMenuOpen,
	module,
	index,
	leftMenuOpen,
	fontColor,
}) {
	const sAction = useAction();
	const translatedLabel = utils.translate(data.label);

	/***/
	const handleClick = () => {
		sAction.dsSet('view/module', data?.name);

		if (window.innerWidth <= 900) {
			setMenuOpen(false);
		}
	};

	let redirectTo = data.standardModule ? `/list/${data?.name}` : `/${data?.name}`;

	let activeClass = module === data?.name ? 'subMenuItem-active' : '';

	if (!leftMenuOpen) {
		return (
			<Link
				className={`iconfas-${data.icon} subMenuItem__closeMenu ${index === 0 ? 'subMenuItem__active' : ''} ${activeClass}`}
				onClick={handleClick}
				to={`${utils.getUrlPrefix()}${redirectTo}`}
				title={translatedLabel}
			/>
		);
	}

	return (
		<div className={`subMenuItem ${index === 0 ? 'subMenuItem__active' : ''}`}>
			<div className="subMenuItem__line"></div>
			{/* <div className="subMenuItem__lineRight"></div> */}
			<Link
				onClick={handleClick}
				to={`${utils.getUrlPrefix()}${redirectTo}`}
				className={`subMenuItem__link ${activeClass}`}
			>
				<div style={{color: fontColor}}>{translatedLabel}</div>
			</Link>
		</div>
	);
}