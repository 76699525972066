import React from 'react';

/**
 * @export
 * @param {Boolean} transparent
 *
 * @return {JSX}
 */
export default function AppLoader({transparent}) {
	return (
		<>
			{!transparent ? <div className="appLoader__overlay"></div> : null}
			<div className="appLoader"></div>
		</>
	);
}
