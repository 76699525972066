import React from 'react';

/**
 * @export
 * @param {String} value
 * @param {String} className
 * @param {Object} style
 *
 * @return {JSX}
 */
export default function ListViewCheckbox({value, className, style}) {
	return (
		<td className={`${className} listViewCheckbox`} style={style}>
			<span className={`iconfas-${value == 1 ? 'check' : 'uncheck'} listViewCheckbox__${value == 1 ? 'check' : 'uncheck'}`} />
		</td>
	);
}
