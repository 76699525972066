import React, {useEffect, useState, Fragment} from 'react';
import utils from '../../utils';
import SearchBox from '../SearchBox/SearchBox';
import useAction from '../../store/actions';
import Report from './Report';
import Loader from '../Loader/Loader';

export default function Reports({translate, reportData, prefix}) {
	const sAction = useAction();
	const [reports, setReports] = useState({});
	const [reportsState, setReportsState] = useState(() => {
		const storedReports = localStorage.getItem(`offerto_reportsMenu_${utils.getUrlPrefix()}`);
		return storedReports ? JSON.parse(storedReports) : {};
	});
	const [search, setSearch] = useState('');
	const [load, setLoad] = useState(false);

	const openReportCategory = (category, open) => {
		const updatedState = {...reportsState, [category]: !open};
		setReportsState(updatedState);
		localStorage.setItem(`offerto_reportsMenu_${utils.getUrlPrefix()}`, JSON.stringify(updatedState));
	};

	useEffect(() => {
		setLoad(true);
		utils
			.post('getReportList', {module: 'report'})
			.then((res) => {
				if (res.data) {
					setReports(res.data);
				}
				setLoad(false);
			})
			.catch((error) => {
				console.error(error);
				setLoad(false);
			});

		return () => {
			sAction.dsSet(`${prefix}/report`, {});
		};
	}, []);

	const setReportId = (report) => {
		sAction.dsSet(`${prefix}/report`, report);
	};

	const reportIsInCategory = (reports, byId) => {
		if (!search && !byId) {
			return true;
		}
		let isIn = false;
		reports?.forEach((report) => {
			const name = translate?.[report.name] || report.name;
			if (byId) {
				if (report?.id === reportData.id) {
					isIn = true;
				}
			} else {
				if (name.toLowerCase()?.includes(search?.toLowerCase())) {
					isIn = true;
				}
			}
		});
		return isIn;
	};

	const renderReports = (reports) => {
		return reports.map((report) => {
			const name = translate?.[report.name] || report.name;
			if (name.toLowerCase().includes(search?.toLowerCase())) {
				return (
					<div
						onClick={() => setReportId(report)}
						className={`reports__list-report ${report.id === reportData.id ? 'reports__list-reportActive' : ''}`}
						key={report.id}>
						<div className="reports__list-icon iconfas-document" />
						<div>{name}</div>
					</div>
				);
			}
		});
	};

	const renderReportsCategory = () => {
		return Object.keys(reports)?.map((key) => {
			const category = reports[key];
			const name = translate?.[category.name] || category.name;
			if (reportIsInCategory(category?.reports) || name.toLowerCase()?.includes(search?.toLowerCase())) {
				return (
					<Fragment key={key}>
						<div
							onClick={() => openReportCategory(key, !!reportsState?.[key])}
							className={`reports__list-category ${reportIsInCategory(category.reports, true) ? 'reports__list-categoryActive' : ''}`}
						>
							<div className={`reports__list-icon iconfas-${category.icon}`} />
							<div>
								{name}
							</div>
						</div>
						{reportsState?.[key] ? <div className="reports__list-reportList">{renderReports(category?.reports)}</div> : null}
					</Fragment>
				);
			}
		});
	};

	const reportsCategoryHeader = utils.translate('LBL_REPORTS_CATEGORY_HEADER');

	return (
		<div className="reports">
			<div className="reports__list">
				<div className="reports__list-searchBox">
					<SearchBox onChangeSearch={(e) => setSearch(e.target.value)} />
				</div>
				<div className="reports__list-content">
					{load ? <div className="loadBox"><Loader /></div> :
						<>
							<div className="reports__list-header">
								{reportsCategoryHeader}
							</div>
							{renderReportsCategory()}
						</>}
				</div>
			</div>
			<div className="reports__content">
				<Report translate={translate} reportDataBase={reportData} />
			</div>
		</div>
	);
}
