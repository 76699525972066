import utils from '../../utils';
import useAction from '../../store/actions';
import toast from 'react-hot-toast';

/**
 * @export
 * @param {String} module
 * @param {String | Number} recordId
 * @param {String} prefix
 * @param {Function} saveDetail
 *
 * @return {Function}
 */
export default function exportExternal(module, recordId, prefix, saveDetail) {
	const sAction = useAction();
	const labelExportExternal = utils.translate('LBL_EXTERNAL_EXPORT_SUCCESS');
	const labelExporting = utils.translate('LBL_EXPORTING');
	const labelError = utils.translate('LBL_ERROR');

	return (buttonData) => {
		sAction.confirmPopup('LBL_EXPORT_TO_EXTERN_SYSTEM', async () => {
			const resultPromise = utils.post('exportExternal', {
				module: module,
				recordId: recordId,
				name: buttonData.name,
			});

			toast.promise(resultPromise, {
				loading: labelExporting,
				success: labelExportExternal,
				error: labelError,
			});

			try {
				const result = await resultPromise;
				if (result.data === 1) {
					sAction.dsSet(`${prefix}/detail/fields/status/value`, 'approved');
					saveDetail({status: 'approved'}, true);
				}
			} catch (error) {
				console.error(error);
			}
		});
	};
}
