import React from 'react';
import useAction from '../../../store/actions';
import utils from '../../../utils';

/**
 * @export
 * @param {String} name
 * @param {String} value
 * @param {Object} options
 * @param {String} className
 * @param {String} prefix
 * @param {Object} style
 * @param {Object} timeline
 *
 * @return {JSX}
 */
export default function ListViewEnum({
	name,
	value,
	options,
	className,
	prefix,
	style,
	timeline,
}) {
	const sAction = useAction();
	let timelineDef = sAction.dsGet(`${prefix}/list/timeline`) || timeline;
	let color = '';
	if (!timelineDef) {
		timelineDef = sAction.dsGet(`${prefix}/timeline`);
	}
	if (timelineDef) {
		color = timelineDef[name]?.values[value] || timelineDef?.values[value];
	}

	const enumOptions = utils.getEnum(options);

	const fieldStyle = color ? {backgroundColor: `rgba(${utils.hexToRgb(color)}, 0.7)`, border: `1px solid ${color}`} : {};

	return (
		<td className={`${className} listViewEnum`} style={style}>
			<div
				className={`listViewEnum__box ${timelineDef ? 'listViewEnum__timeline' : ''}`}
				style={fieldStyle}
			>
				{enumOptions[value]}
			</div>
		</td>
	);
}
