import React, { useState, useEffect, useMemo, lazy, Suspense } from 'react';
import useAction from '../../../../store/actions';
import { Skeleton } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import Popover from '../../../Popover/Popover';

// Lazy load the LineField component
const LineField = lazy(() => import('./LineField'));

/**
 *
 * @export
 * @param {Array} rows
 * @param {Number} groupIndex
 * @param {String} prefix
 * @param {String} module
 * @param {Object} linesDef
 * @param {Object} row
 * @param {Number} rowIndex
 * @param {Boolean} linesHaveGroups
 * @param {Function} initCalcLines
 *
 * @return {JSX}
 */
export default function LineRow({
	rows,
	groupIndex,
	prefix,
	module,
	linesDef,
	row,
	rowIndex,
	linesHaveGroups,
	initCalcLines,
}) {
	const sAction = useAction();
	const [calculate, setCalculate] = useState(false);
	const { attributes, listeners, setNodeRef, transform } = useSortable({ id: row?.id });
	const [open, setOpen] = useState(false);
	const [anchor, setAnchor] = useState(null);

	useEffect(() => {
		setCalculate(true);
	}, [row]);

	/**
	 *
	 * @param {Number} deleteIndex
	 * @param {Object} row
	 *
	 * @return {JSX}
 	*/
	const deleteRow = (deleteIndex, row) => {
		const oldRows = rows; // Create a copy of rows for immutability
		oldRows.splice(deleteIndex, 1);
		if (row.isSubProductForProductId === 0 || row.mainProductId) {
			oldRows.forEach((item) => {
				if (item.isSubProductForProductId === row.id || item.isSubProductForProductId === row.mainProductId) {
					item.isSubProductForProductId = 0;
				}
			});
		}

		if (linesHaveGroups) {
			sAction.dsSet(`${prefix}/detail/linesData/lines/${groupIndex}/rows`, oldRows);
		} else {
			sAction.dsSet(`${prefix}/detail/linesData/lines`, oldRows);
		}
	};

	// Memoize the rendering of fields to prevent unnecessary recalculations
	const renderFields = useMemo(() => {
		return Object.keys(linesDef).map((key, i) => {
			if (!linesDef[key].hidden) {
				return (
					<Suspense fallback={<td><Skeleton variant="rectangular" height={32} className="lineField__skeleton" /></td>} key={key}>
						<LineField
							def={linesDef[key]}
							value={row[key]} // value will be re-evaluated if row[key] changes
							groupIndex={groupIndex}
							rowIndex={rowIndex}
							prefix={prefix}
							module={module}
							linesDef={linesDef}
							rows={rows}
							currentRow={row}
							fieldIndex={i}
							linesHaveGroups={linesHaveGroups}
							setCalculate={setCalculate}
							calculate={calculate}
							initCalcLines={initCalcLines}
						/>
					</Suspense>
				);
			}
			return null; // Always return something from map
		});
		// Create a dependency array for all row[key] values
	}, [
		...Object.keys(linesDef).map((key) => row[key]),
		...Object.keys(linesDef).map((key) => row?.isSubProductForProductId),
		calculate,
		rowIndex,
		groupIndex,
	]);

	// Apply transform to move the row on drag
	const rowStyle = {
		transform: transform
			? `translate3d(${transform.x}px, ${transform.y}px, 0)`
			: undefined,
	};

	/**
	 * @returns {void}
	*/
	const unsetSubProduct = () => {
		sAction.dsSet(`${prefix}/detail/linesData/lines/${groupIndex}/rows/${rowIndex}/isSubProductForProductId`, 0);
	};

	/**
	 * @returns {void}
	*/
	const handleOpen = (e) => {
		setAnchor(e.currentTarget);
		setOpen(!open);
	};

	const actions = [
		{name: 'unsetSubProduct', icon: 'deleteRelation', label: 'LBL_UNSET_SUB_PRODUCT', onClick: () => unsetSubProduct()},
	];

	return (
		<tr
			ref={setNodeRef}
			{...attributes}
			style={rowStyle}
			className={`linesRow ${row.isSubProductForProductId > 0 ? 'linesRow__subProduct' : ''}`}
			key={row.id}
		>
			<td className="linesRow__icons">
				<span {...listeners} className="linesRow__icons-dnd iconfas-grip" />
				<span onClick={() => deleteRow(rowIndex, row)} className="linesRow__icons-delete iconfas-delete" />
			</td>
			{renderFields}
			{linesHaveGroups && row.isSubProductForProductId !== 0 &&
			<td onClick={(e) => handleOpen(e)}>
				<span className="linesRow__menu iconfas-dotsMenu" />
				<Popover id={'lineMoreOptions' + row.id} anchor={anchor} open={open} options={actions} />
			</td>}
		</tr>
	);
}