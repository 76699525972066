import useAction from '../../store/actions';


/**
 * @export
 * @param {String} module
 * @param {Number | String} recordId
 *
 * @return {Function}
 */
export default function detailChanges(module, recordId) {
	const sAction = useAction();

	return () => {
		sAction.openPopup('DetailChangesPopup', 'LBL_LIST_OF_CHANGES', false, {module: module, recordId: recordId});
	};
}
