/**
 * @export
 * @param {String} message
 * @param {String} icon
 *
 * @return {Function}
 */
export default function hideInfoBanner() {
	return (dispatch) => {
		dispatch({
			type: 'HIDE_INFO_BANNER',
		});
	};
}