/**
 * @export
 * @param {Object} translate
 * @param {Object} usrInfo
 * @param {Object} leftMenu
 * @param {Object} usrRight
 * @param {String} module
 * @param {String | Number} recordId
 * @param {Object} currencies
 * @param {Object} systemSettings
 * @param {Object} systemRoles
 *
 * @return {Function}
 */
export default function initData(
	translate,
	usrInfo,
	leftMenu,
	usrRight, module,
	recordId,
	currencies,
	systemSettings,
	systemRoles,
) {
	return (dispatch) => {
		dispatch({
			type: 'INIT_DATA',
			payload: {translate, usrInfo, leftMenu, usrRight, module, recordId, currencies, systemSettings, systemRoles},
		});
	};
}
