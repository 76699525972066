import useAction from '../../store/actions';

/**
 * @export
 * @param {String | Number} recordId
 *
 * @return {Function}
 */
export default function setUserPassword(recordId) {
	const sAction = useAction();

	return () => {
		sAction.openPopup('SetUserPassword', 'LBL_SET_USER_PASSWORD', true, {recordId: recordId});
	};
}
