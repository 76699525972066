import React, {useEffect, useRef, useState} from 'react';
import utils from '../../utils';
import NotificationsList from './NotificationsList';
import NotificationsSettings from './NotificationsSettings';

export default function Notifications({
	userData,
	setNotification,
	setNotifyNumber,
	notifyNumber,
	closeNotifications,
}) {
	const notificationRef = useRef(null);
	const [view, setView] = useState('list');

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (notificationRef.current && !notificationRef.current.contains(event.target)) {
				setNotification(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [notificationRef]);

	const viewDef = {
		list: <NotificationsList closeNotifications={closeNotifications} setNotifyNumber={setNotifyNumber} notifyNumber={notifyNumber} />,
		settings: <NotificationsSettings userData={userData} />,
	};

	const notificationsLabel = utils.translate('LBL_NOTIFICATIONS');
	const settingsLabel = utils.translate('LBL_SETTINGS_NOTIFICATIONS');

	return (
		<div ref={notificationRef} className="notifications">
			<div className="notifications__header">
				<div className="notifications__header-box">
					<div className="notifications__header-text">
						{view === 'list' ? <span className="iconfas-notifications" /> : <span className="iconfas-settings" />}
						<div>{view === 'list' ? notificationsLabel : settingsLabel}</div>
					</div>
					<div>
						<span
							onClick={() => setView(view === 'settings' ? 'list' : 'settings')}
							className={`notifications__header-icon ${view === 'settings' ? 'iconfas-list' : 'iconfas-config'}`}
						/>
					</div>
				</div>
			</div>
			<div className="notifications__content">
				{viewDef[view]}
			</div>
		</div>
	);
}