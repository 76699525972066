/**
 * @export
 * @param {Array} arr
 * @param {Number} fromIndex
 * @param Number} toIndex
 *
 * @return {Array}
 */
export default function moveItemInArray(arr, fromIndex, toIndex) {
	// Create a copy of the original array to avoid modifying it
	const arrCopy = [...arr];

	// Ensure the indices are within the bounds of the array
	if (fromIndex < 0 || fromIndex >= arrCopy.length || toIndex < 0 || toIndex >= arrCopy.length) {
		console.error('Index out of bounds error [E01001]');
		return arrCopy;
	}

	// Remove the item from the copied array
	const [item] = arrCopy.splice(fromIndex, 1);

	// Insert the item at the new position
	arrCopy.splice(toIndex, 0, item);

	return arrCopy;
}
