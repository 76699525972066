import React from 'react';
import utils from '../../../utils';
import {Link} from 'react-router-dom';
import useAction from '../../../store/actions';
import ListViewEnum from '../../ListView/ListViewFields/ListViewEnum';

export default function Sum({columns, data, total, headerLabel, timeline}) {
	const sAction = useAction();

	const renderHeader = () => {
		return Object.keys(columns).map((columnKey) => {
			const column = columns[columnKey];
			const style = {...column.style, width: column.width};

			return (
				<th className="reportTable__table-headerCell" style={style} key={column.name}>
					{utils.translate(column.label)}
				</th>
			);
		});
	};

	const renderRow = (row) => {
		return Object.keys(columns).map((key, i) => {
			const column = columns[key];
			const data = row[column.name];
			const options = utils.get;
			switch (column.type) {
			case 'text':
				return (
					<td className="reportTable__table-cell" key={i}>
						{data}
					</td>
				);
			case 'currency':
				return (
					<td className="reportTable__table-currency reportTable__table-cell" key={i}>
						{data}
					</td>
				);
			case 'link':
				if (data.id != 0) {
					return (
						<td className="reportTable__table-cell" key={i}>
							<Link className="ListViewLink__link" onClick={() => sAction.clearModuleData()} to={`/${utils.getUrlPrefix()}/detail/${data?.module}/${data?.id}`}>
								<span className={`iconfas-${data?.module}`}></span>
								{data?.value}
							</Link>
						</td>
					);
				} else {
					return (
						<td className="reportTable__table-cell" key={i}>
							{data?.value}
						</td>
					);
				}
			case 'enum':
				return (
					<ListViewEnum
						className="reportTable__table-cell"
						value={data}
						name={data}
						options={column?.options}
						timeline={column?.timeline}
						key={i}
					/>
				);
			default:
				return (
					<td className="reportTable__table-cell" key={i}>
						{data}
					</td>
				);
			}
		});
	};

	const renderData = () => {
		return data.map((row, i) => {
			return (
				<tr className="reportTable__table-row" key={i}>
					{renderRow(row)}
				</tr>
			);
		});
	};

	const renderSumTotal = () => {
		return Object.keys(columns).map((key) => {
			const value = total[key];
			return (
				<td className={`reportTable__table-totalCell ${columns[key]?.type === 'currency' ? 'reportTable__table-currency' : ''}`} key={key}>
					{value}
				</td>
			);
		});
	};

	const renderTotal = () => {
		if (!total) {
			return;
		}

		if (Object.keys(total)?.length > 0) {
			return (
				<>
					<tr>
						<td className="reportTable__table-divideCell"></td>
					</tr>
					<tr>{renderSumTotal()}</tr>
				</>
			);
		}
	};

	return (
		<div className={`reportTable ${total ? 'reportTable__total' : ''}`}>
			<div className="reportTable__header">{utils.translate(headerLabel)}</div>
			<table className="reportTable__table">
				<thead>
					<tr>{renderHeader()}</tr>
				</thead>
				<tbody>
					<tr>
						<td className="reportTable__table-divideCell"></td>
					</tr>
					{renderData()}
					{renderTotal()}
				</tbody>
			</table>
		</div>
	);
}
