import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import ErrorBoundary from './pages/Error/ErrorBoundary';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Define your custom theme
const theme = createTheme({
	typography: {
		fontFamily: 'Cabin, Roboto, sans-serif', // Replace with your font
	},
});

// Render the app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ErrorBoundary>
		<BrowserRouter>
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</Provider>
		</BrowserRouter>
	</ErrorBoundary>
);