import React, { createContext, useState, useEffect, useCallback } from 'react';
import utils from '../utils';
import toast from 'react-hot-toast';
import useAction from '../store/actions';
import { useNavigate } from 'react-router-dom';

export const ConfigContext = createContext();

/**
 * @export
 *
 * @param {JSX} children
 *
 * @return {JSX}
 */
export const ConfigContextProvider = ({ children, systemSettings }) => {
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [activeTab, setActiveTab] = useState('company');
	const [state, setState] = useState(systemSettings);
	const [emailError, setEmailError] = useState({});
	const [error, setError] = useState({});
	const navigate = useNavigate();
	const sAction = useAction();
	const urlPrefix = utils.getUrlPrefix();

	useEffect(() => {
		if (state.email) {
			validateEmail(state.email, 'email');
		}
		if (state.invoiceEmail) {
			validateEmail(state.invoiceEmail, 'invoiceEmail');
		}

		const tab = window.location.pathname?.split('/')?.slice(-1).pop();
		if (tab === 'config') {
			setTab('company');
		} else {
			setActiveTab(tab);
			setTab(tab);
		}
	}, []);

	useEffect(() => {
		setState(systemSettings);
	}, [systemSettings]);

	const invalidEmailLabel = utils.translate('LBL_INVALID_EMAIL');

	/**
	 * Handles change of the company logo
	 *
	 * @param {String} value
	 *
	 * @returns {void}
	 */
	const validateEmail = (value, field) => {
		let newState = emailError;
		if (!utils.validateEmail(value) && value !== '') {
			newState = ({...emailError, [field]: invalidEmailLabel});
		} else if (value === '') {
			newState = ({...emailError, [field]: ''});
		} else {
			newState = ({...emailError, [field]: ''});
		}

		setEmailError(newState);

		let disableSave = false;
		Object.keys(newState).forEach((key) => {
			const field = newState[key];
			if (field) {
				disableSave = true;
			}
		});

		setSaveDisabled(disableSave);
	};

	/**
	 * Handles setting value into the config state
	 *
	 * @param {String} name
	 * @param {*} value
	 *
	 * @returns {void}
	 */
	const setValue = (name, value) => {
		if (saveDisabled) {
			setSaveDisabled(false);
		}

		setState({...state, [name]: value});
	};

	const labelSuccess = utils.translate('LBL_SAVE_SUCCESSFUL');
	const labelError = utils.translate('LBL_ERROR');

	const saveSettings = useCallback((disableToast) => {
		const postData = { ...state };
		delete postData.companyLogo;
		utils.post('saveSystemSettings', postData).then((res) => {
			if (res.data !== 0) {
				if (!disableToast) {
					toast.success(labelSuccess);
				}
				sAction.dsSet('config/systemSettings', state);
			} else {
				toast.error(labelError);
			}
		}).catch((error) => {
			console.error(error);
		});
	}, [state, labelSuccess, labelError, sAction]);

	/**
     *
     *
     * @param {String} tab
     */
	const setTab = (tab) => {
		navigate(`/${urlPrefix}/config/${tab}`);
		setActiveTab(tab);
	};

	const contextValues = {
		saveDisabled,
		setSaveDisabled,
		activeTab,
		setTab,
		state,
		setState,
		emailError,
		setEmailError,
		error,
		setError,
		validateEmail,
		setValue,
		saveSettings,
	};

	return (
		<ConfigContext.Provider value={contextValues}>
			{children}
		</ConfigContext.Provider>
	);
};
