import React from 'react';
import useAction from '../../store/actions';
import utils from '../../utils';

/**
 * @export
 * @param {Object} info
 * @param {Function} getCalendar
 * @param {String} calType month, week or day
 * @param {Date} date
 *
 * @return {JSX}
 */
export default function CalendarEvent({ info, getCalendar, calType, date }) {
	const sAction = useAction();

	const eventData = info.event._def;
	const extendedProps = eventData.extendedProps;
	const time = info.timeText.replaceAll(' ', '').split('-');
	const fromTime = time[0]?.length === 1 ? time[0] + ':00' : time[0];
	const toTime = (time[1]?.length <= 2 ? time[1] + ':00' : time[1]) || '0:00';

	/**
	 * When is clicked on event popup with detail will appear
 	 *
 	 * @return {void}
 	 */
	const handleClick = () => {
		utils
			.post('getDetail', { id: extendedProps.recordId, module: 'event' })
			.then((res) => {
				sAction.openPopup(
					'DetailFormPopup',
					'LBL_EVENT',
					false,
					{ module: 'event', recordId: extendedProps.recordId },
					() => {
						getCalendar(calType, date);
					}
				);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<div className="calendarEvent" onClick={handleClick}>
			<div className="calendarEvent__header">
				<span className="iconfas-event calendarEvent__icon"></span>
				<p className="calendarEvent__text">{eventData.title}</p>
			</div>
			<div className="calendarEvent__info">
				<div className="calendarEvent__info-time">{fromTime + ' - ' + toTime}</div>
			</div>
		</div>
	);
}