import React, {useState, useEffect} from 'react';
import utils from '../../../../utils';
import useAction from '../../../../store/actions';

/**
 *
 *
 * @export
 * @param {Array} rows
 * @param {Boolean} allLines
 * @param {String} prefix
 * @param {String} module
 *
 * @return {*}
 */
export default function LinesGroupTotal({rows, allLines, prefix, module}) {
	const sAction = useAction();
	const userRoleType = sAction.dsGet('user/roleTypeId');
	const [countTotals, setCountTotals] = useState(false);
	const [totals, setTotals] = useState({
		costPrice: 0,
		withoutVat: 0,
		discount: 0,
		totalWeight: 0,
		total: 0,
	});

	useEffect(() => {
		const initTotals = {
			costPrice: 0,
			withoutVat: 0,
			discount: 0,
			totalWeight: 0,
			total: 0,
		};

		rows.forEach((row) => {
			initTotals.costPrice = (parseFloat(row.purchasePrice) * Number(row.amount)) + initTotals.costPrice;
			initTotals.withoutVat = parseFloat(row.totalLinePrice) + initTotals.withoutVat;
			initTotals.total = parseFloat(row.totalLinePriceWithTax) + initTotals.total;
			let discount = parseFloat(row.discount) * parseFloat(row.amount);
			if (row.discountType === '%') {
				discount = (parseFloat(row.sellingPrice) - parseFloat(row.priceAfterDiscount)) * parseFloat(row.amount);
			}
			initTotals.discount = parseFloat(discount) + parseFloat(initTotals.discount);
			if (row.weight) {
				initTotals.totalWeight = (parseFloat(row.weight) * parseFloat(row.amount)) + parseFloat(initTotals.totalWeight);
			}
		});

		setTotals(initTotals);

		// Prevent infinite loop
		setCountTotals(true);
	}, [rows, rows.length]);

	useEffect(() => {
		if (rows.length > 0 && allLines && totals.withoutVat > 0 && countTotals) {
			sAction.dsSet(`${prefix}/detail/fields/price/value`, totals?.withoutVat.toFixed(2));
			sAction.dsSet(`${prefix}/detail/fields/priceTax/value`, totals?.total.toFixed(2));
			sAction.setDetailChanges(prefix,{
				price: totals?.withoutVat.toFixed(2),
				priceTax:  totals?.total.toFixed(2)
			});
		}

		// Prevent infinite loop
		setCountTotals(false);
	}, [countTotals]);

	const labelTotalCosts = utils.translate('LBL_COST_TOTAL');
	const labelPriceTotal = utils.translate('LBL_PRICE_TOTAL');
	const labelDiscountTotal = utils.translate('LBL_DISCOUNT_TOTAL');
	const labelTotal = utils.translate('LBL_TOTAL');
	const labelTotalWeight = utils.translate('LBL_TOTAL_WEIGHT');

	return (
		<>
			{userRoleType > 10 && module === 'quote' ?
				<td className="groupTotal">
					<div>{labelTotalCosts}</div>
					<div>{utils.formatNumber(totals.costPrice, 2)}</div>
				</td> : <td></td>}
			{module !== 'quote' ?
				<>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
				</>
				: null}
			<td className="groupTotal">
				<div>{labelPriceTotal}</div>
				<div>{utils.formatNumber(totals.withoutVat, 2)}</div>
			</td>
			{module === 'quote' ?
				<td className="groupTotal">
					<div>{labelDiscountTotal}</div>
					<div>{utils.formatNumber(totals.discount, 2)}</div>
				</td> : null}
			{module === 'quote' && userRoleType > 10 ?
				<>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
				</>
				: null}
			{module === 'quote' && userRoleType == 10 ?
				<td></td>
				: null}
			<td className="groupTotal">
				<div>{labelTotal}</div>
				<div>{utils.formatNumber(totals.total, 2)}</div>
				{allLines ?
					<>
						<div className="groupTotal__weight">{labelTotalWeight}</div>
						<div>{utils.formatNumber(totals.totalWeight, 3)} Kg</div>
					</>
					: null}
			</td>
		</>
	);
}