import utils from '..';

/**
 * @export
 * @param {String} module
 * @param {Number | String} recordId
 * @param {String} name
 *
 * @return {Function}
 */
export default function printFile(module, recordId, name) {
	utils
		.post('print', {module, recordId, name})
		.then((res) => {
			if (res.data) {
				window.open(res.data, '_blank');
			}
		})
		.catch((error) => {
			console.error(error);
		});
}
