/**
 * Gives prefix on popup decreased by given number
 *
 * @export
 * @param {String} originalString
 * @param {Number} decreaseBy
 * @return {String}
 */
export default function decreasePopupPrefix(originalString, decreaseBy) {
	const parts = originalString.split('/');
	if (parts.length !== 2 || parts[0] !== 'popups') {
		throw new Error('Invalid input format. Expected format: "popups/{number}".');
	}

	const currentNumber = parseInt(parts[1], 10);
	if (isNaN(currentNumber)) {
		throw new Error('Invalid number in the input string.');
	}

	const newNumber = currentNumber - decreaseBy;
	if (newNumber < 0) {
		throw new Error('Resulting number cannot be negative.');
	}

	return `popups/${newNumber}`;
}
