import useAction from '../../store/actions';
import utils from '../../utils';

/**
 * @export
 * @param {String} module
 * @param {String | Number} recordId
 * @param {String} prefix
 *
 * @return {Function}
 */
export default function createProductVariant(module, recordId, prefix) {
	const sAction = useAction();
	const productVariant = sAction.dsGet(`${prefix}/detail/productVariant`);

	return () => {
		sAction.openPopup('SimpleInputPopup', 'LBL_NAME_OF_CONSTRUCTION_VARIANT', true, {inputLabel: 'LBL_NAME'}, (inputValue) => {
			utils
				.post('createProductVariant', {module: module, recordId: recordId, name: inputValue})
				.then((res) => {
					if (res.data > 0) {
						const variants = productVariant?.variants || [];
						variants.push({
							id: res.data,
							name: inputValue,
						});
						sAction.dsSet(`${prefix}/detail/productVariant/variants`, variants);
						sAction.closePopup();
					}
			 	})
			 	.catch((error) => {
			 		console.error(error);
				});
		});
	};
}
