import React, { Component } from 'react';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null, errorInfo: null };
	}

	// This method is called when an error is thrown
	static getDerivedStateFromError(error) {
		// Update state so the next render shows the fallback UI
		return { hasError: true };
	}

	// This method logs the error information
	componentDidCatch(error, errorInfo) {
		this.setState({error, errorInfo});
		console.error('Error caught by Error Boundary:', error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// Render any fallback UI
			return (
				<ErrorPage
					firstMessage={'Oops! Something went wrong. We are sorry. We will fix it soon.'}
					secondMessage={'Please send the message to the administrator.'}
					error={this.state.error}
					errorInfo={this.state.errorInfo}
				/>
			);
		}

		// Render children components when there are no errors
		return this.props.children;
	}
}

export default ErrorBoundary;
