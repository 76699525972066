import React, {useState, useEffect} from 'react';
import { TextField } from '@mui/material';
import utils from '../../../utils';
import toast from 'react-hot-toast';

/**
 * @export
 * @param {Number | String} value
 * @param {Number | String} id
 * @param {String} module
 * @param {Number | String} parentId
 * @param {Number | String} variantId
 * @param {Object} style
 *
 * @return {JSX}
 */
export default function ListViewEditableNumber({value, id, module, parentId, variantId, style}) {
	const [inputValue, setInputValue] = useState(value);
	const [edited, setEdited] = useState(false);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const labelSuccess = utils.translate('LBL_SUCCESSFULLY_SAVED');
	const labelError = utils.translate('LBL_UNSUCCESSFULLY_SAVED');
	const labelSaving = utils.translate('LBL_SAVING');

	/**
	 * Function saves amount in input
	 *
	 */
	const saveAmount = () => {
		const params = {
			value: inputValue,
			recordId: id,
			parentId: parentId,
			module: module,
		};
		if (variantId) {
			params.variantId = variantId;
		}

		const resultPromise = utils.post(variantId ? 'saveRelatedVariantProductAmount' : 'saveRelatedProductAmount', params);

		toast.promise(resultPromise, {
			loading: labelSaving,
			success: labelSuccess,
			error: labelError,
		});

		if (resultPromise.data === 0) {
			toast.error(labelError);
		} else {
			setEdited(false);
		}
	};

	/**
	 * Handle change of the input
	 *
	 * @param {Event} e
	 */
	const handleChange = (e) => {
		setEdited(true);
		setInputValue(e.target.value);
	};

	return (
		<td className="editableNumber listView__cell" style={style}>
			<TextField
				variant="standard"
				size="small"
				value={inputValue}
				onChange={(e) => handleChange(e)}
				type="number"
				error={inputValue < 0 ? true : false}
				onBlur={() => edited ? saveAmount() : null}
				InputProps={{
					inputProps: { min: 0 }
				}}
			/>
			{edited && inputValue > 0 ?
				<div onClick={saveAmount} className="editableNumber__confirm iconfas-check" /> : <div className="editableNumber__confirm" />
			}
		</td>
	);
}
