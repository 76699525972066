import React from 'react';
import utils from '../../../utils';

/**
 * @export
 * @param {String} value
 * @param {String} className
 * @param {Object} style
 * @param {Object} icon
 * @param {String} module
 * @param {Number | String} id
 * @param {String} columnName
 *
 * @return {JSX}
 */
export default function ListViewPrint({
	value,
	className,
	style,
	icon,
	module,
	id,
	columnName,
}) {
	return (
		<td className={`${className} listViewPrint`} style={style}>
			{value &&
			<div onClick={() => utils.printFile(module, id, columnName)}>
				<span className={`listViewPrint__icon iconfas-${icon}`}></span>
				<span>{value}</span>
			</div>}
		</td>
	);
}
