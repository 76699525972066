import React, { Suspense, memo, lazy } from 'react';
import utils from '../../../../utils';
import { Skeleton } from '@mui/material';
import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import useAction from '../../../../store/actions';
import { useSortable } from '@dnd-kit/sortable';

const LinesGroup = lazy(() => import('./LinesGroup'));

/**
 * @param {*} prevProps
 * @param {*} nextProps
 *
 * @return {Boolean}
 */
function arePropsEqual(prevProps, nextProps) {
	return (
		prevProps.group === nextProps.group &&
        prevProps.index === nextProps.index &&
		prevProps.discount === nextProps.discount &&
        prevProps.linesLength === nextProps.linesLength &&
        prevProps.allRows === nextProps.allRows
	);
}

const MemoizedGroup = memo(function MemoizedGroup({
	group,
	index,
	linesDef,
	prefix,
	linesData,
	module,
	linesHaveGroups,
	addRow,
	allRows,
	addNewGroup,
	initCalcLines,
	linesLength,
}) {
	const { attributes, listeners, setNodeRef, transform } = useSortable({ id: group?.id });
	const sAction = useAction();

	/**
	 * @param {Event} event
	 *
	 * @returns {void}
	 */
	const handleDragEnd = (event) => {
		const { over, active } = event;
		if (over) {
			const fromIndex = active.data.current.sortable.index;
			const toIndex = over.data.current.sortable.index;
			sAction.dsSet(`${prefix}/detail/linesData/lines/${index}/rows`, utils.moveItemInArray(group?.rows, fromIndex, toIndex));
		}
	};

	const groupStyle = {
		transform: transform
			? `translate3d(${transform.x}px, ${transform.y}px, 0)`
			: undefined,
	};

	return (
		<Suspense fallback={<Skeleton className="linesGroup__load" variant="rectangular" height={200} />}>
			<div
				{...attributes}
				style={groupStyle}
				ref={setNodeRef}
				className="linesGroup__groupWrapper"
			>
				<DndContext onDragEnd={handleDragEnd}>
					<SortableContext items={group?.rows}>
						<LinesGroup
							linesDef={linesDef}
							prefix={prefix}
							index={index}
							group={group}
							linesData={linesData}
							module={module}
							linesHaveGroups={linesHaveGroups}
							addRow={addRow}
							allRows={allRows}
							addNewGroup={addNewGroup}
							initCalcLines={initCalcLines}
							linesLength={linesLength}
							listeners={listeners}
						/>
					</SortableContext>
				</DndContext>
			</div>
		</Suspense>
	);
}, arePropsEqual);

export default MemoizedGroup;
