import React from 'react';
import utils from '../../../../utils';


/**
 * @export
 *
 * @param {Object} state state of the system config
 *
 * @return {JSX}
 */
export default function Pohoda({state}) {
	return (
		<div className="configPohoda">
			<div className="configPohoda__box">
				<div className="config__label">{utils.translate('LBL_POHODA_URL')}</div>
				<span className="configPohoda__url">{state.clientURLPohodaXML}</span>
				<span className="configPohoda__icon iconfas-copy" onClick={() => utils.copyToClipboard(state.clientURLPohodaXML)} />
			</div>
			<div className="configPohoda__box">
				<a href={state.clientURLPohodaXML + '&a=show'} target="_blank" className="configPohoda__link" rel="noreferrer">
					{utils.translate('LBL_POHODA_URL_SHOW')}
				</a>
			</div>
			<div className="configPohoda__box">
				<div className="config__label configPohoda__warning">
					{utils.translate('LBL_WARNING')} {utils.translate('LBL_POHODA_ICO_SETTINGS')}
				</div>
			</div>
			<div className="configPohoda__box">
				<div className="config__label">{utils.translate('LBL_STEP_1')}</div>
				<span className="configPohoda__desc">{utils.translate('LBL_STEP_1_POHODA_DESC')}</span>
			</div>
			<div className="configPohoda__box">
				<div className="config__label">{utils.translate('LBL_STEP_2')}</div>
				<span className="configPohoda__desc">{utils.translate('LBL_STEP_2_POHODA_DESC')}</span>
				<div className="configPohoda__imageBox">
					<span>
						<img className="configPohoda__images" src="/images/pohoda/pohoda1.jpg" />
					</span>
					<span>
						<img className="configPohoda__images" src="/images/pohoda/pohoda2.jpg" />
					</span>
				</div>
			</div>
			<div className="configPohoda__box">
				<div className="config__label">
					{utils.translate('LBL_STEP_3')} {utils.translate('LBL_STEP_3_POHODA')}
				</div>
				<span className="configPohoda__desc">{utils.translate('LBL_STEP_3_POHODA_DESC')}</span>
				<div className="configPohoda__imageBox">
					<span>
						<img className="configPohoda__images" src="/images/pohoda/pohoda3.jpg" />
					</span>
				</div>
			</div>
			<div className="configPohoda__box">
				<div className="config__label">{utils.translate('LBL_STEP_4')}</div>
				<span className="configPohoda__desc">{utils.translate('LBL_STEP_4_POHODA_DESC')}</span>
				<div className="configPohoda__imageBox">
					<span>
						<img className="configPohoda__images" src="/images/pohoda/pohoda4.jpg" />
					</span>
				</div>
			</div>
			<div className="configPohoda__box">
				<div className="config__label">{utils.translate('LBL_STEP_5')}</div>
				<span className="configPohoda__desc">{utils.translate('LBL_STEP_5_POHODA_DESC')}</span>
				<div className="configPohoda__imageBox">
					<span>
						<img className="configPohoda__images" src="/images/pohoda/pohoda5.jpg" />
					</span>
				</div>
			</div>
			<div className="configPohoda__box">
				<div className="config__label">
					{utils.translate('LBL_STEP_6')} {utils.translate('LBL_STEP_6_POHODA')}
				</div>
				<span className="configPohoda__desc">{utils.translate('LBL_STEP_6_POHODA_DESC')}</span>
				<div className="configPohoda__imageBox">
					<span>
						<img className="configPohoda__images" src="/images/pohoda/pohoda6.jpg" />
					</span>
					<span>
						<img className="configPohoda__images" src="/images/pohoda/pohoda7.jpg" />
					</span>
					<span>
						<img className="configPohoda__images" src="/images/pohoda/pohoda8.jpg" />
					</span>
				</div>
			</div>
		</div>
	);
}
