import React, { useState } from 'react';
import utils from '../../../utils';
import useAction from '../../../store/actions';


/**
 * @export
 * @param {String} path
 * @param {String} className
 * @param {Number | String} recordId
 * @param {String} module
 * @param {String} recordName
 * @param {Object} style
 *
 * @return {JSX}
 */
export default function ListViewFileLink({
	path,
	className,
	recordId,
	module,
	recordName,
	style,
}) {
	const fileType = path?.split('.')[1];
	const sAction = useAction();


	/***/
	const download = () => {
		utils.downloadFile(module, recordId, recordName, fileType, sAction);
	};

	return (
		<td className={`${className} listViewFile`} style={style}>
			<div className="listViewFile__box">
				<span>
					<a onClick={() => download()} className="listViewFile__link">
						<span className={`listViewFile__icon iconfas-${fileType}`} />
						<span className="listViewFile__name">
							{`.${fileType}`}
						</span>
					</a>
				</span>
			</div>
		</td>
	);
}