/**
 * @export
 * @param {Object} lines
 *
 * @return {Function}
 */
export default function initDetailLines(lines, prefix) {
	return (dispatch) => {
		dispatch({
			type: 'INIT_DETAIL_LINES',
			payload: {lines, prefix},
		});
	};
}