import React, {useContext} from 'react';
import ActionButtons from '../ActionButtons/ActionButtons';
import ButtonBasic from '../Buttons/ButtonBasic';
import { Tabs, Tab } from '@mui/material';
import utils from '../../utils';
import System from './ConfigPages/System';
import Company from './ConfigPages/Company';
import ExternalSystem from './ConfigPages/ExternalSystem';
import Print from './ConfigPages/Print';
import CreditCheck from './ConfigPages/CreditCheck';
import { ConfigContext } from '../../contexts/ConfigContext';

/**
 * @export
 *
 * @param {Object} config system configuration
 *
 * @return {JSX}
 */
export default function Config({config}) {
	const {
		saveDisabled,
		activeTab,
		setTab,
		saveSettings,
	} = useContext(ConfigContext);

	const tabs = {
		company: <Company />,
		settings: <System />,
		print: <Print />,
		creditCheck: <CreditCheck />,
		externalSystems: <ExternalSystem />,
	};

	return (
		<>
			<div className="actionBox">
				<ActionButtons>
					<ButtonBasic id={'saveButton'} label={'LBL_SAVE'} key={'save'} icon={'save'} style={'green'} disabled={saveDisabled} onClick={saveSettings} />
				</ActionButtons>
			</div>
			<div className="config">
				<Tabs className="config__tabs" value={activeTab} onChange={(e, newValue) => setTab(newValue)} aria-label="basic tabs example">
					<Tab value="company" iconPosition="start" icon={<span className="iconfas-company"/>} className="config__tab" label={utils.translate('LBL_COMPANY')} />
					<Tab value="settings" iconPosition="start" icon={<span className="iconfas-settings"/>} className="config__tab" label={utils.translate('LBL_SYSTEM')} />
					<Tab value="print" iconPosition="start" icon={<span className="iconfas-print"/>} className="config__tab" label={utils.translate('LBL_PRINT_SETTINGS')} />
					<Tab value="creditCheck" iconPosition="start" icon={<span className="iconfas-check"/>} className="config__tab" label={utils.translate('LBL_CREDIT_CHECK')} />
					<Tab value="externalSystems" iconPosition="start" icon={<span className="iconfas-fileExport"/>} className="config__tab" label={utils.translate('LBL_EXTERNAL_SYSTEMS')} />
				</Tabs>
				<div className="config__content">
					{tabs[activeTab]}
				</div>
			</div>
		</>
	);
}
