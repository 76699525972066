import utils from '../../../utils';

/**
 * Functions that validate if detail can be saved or not and disable or enable save button
 *
 * @export
 * @param {Object} button
 * @param {Object} viewData
 *
 * @return {Boolean}
 */
export default function saveDetailValidation(button, viewData) {
	return button.name === 'saveDetail' &&
		((utils.validateRequiredFields(viewData?.detail?.fields) &&
		!utils.checkForErrorsInFields(viewData?.detail?.fields))) ?
		false : button.disabled;
}