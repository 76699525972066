import React, { useContext } from 'react';
import Select from '../../FormElements/Select';
import utils from '../../../utils';
import Pohoda from './ExternalSystemSettings/Pohoda';
import { ConfigContext } from '../../../contexts/ConfigContext';

/**
 * @export
 *
 * @return {JSX}
 */
export default function ExternalSystems() {
	const options = utils.getEnum('externalSystem');
	const {
		state,
		setValue
	} = useContext(ConfigContext);

	/**
	 * Render settings of selected external system
	 *
	 * @returns {JSX}
	 */
	const renderSettings = () => {
		switch (state.externalSystem) {
		case 'pohoda':
			return <Pohoda setValue={setValue} state={state} />;
		}
	};

	/**
	 * Set selected external system
	 *
	 * @param {Event} e
	 *
	 * @returns {void}
	 */
	const handleChange = (e) => {
		setValue('externalSystem', e.target.value);
	};

	return (
		<>
			<div className="config__form-externalSystem">
				<Select
					onChange={(e) => handleChange(e)}
					value={state.externalSystem}
					label={utils.translate('LBL_SELECT_EXTERNAL_SYSTEM')}
					fieldName="externalSystem"
					options={options}
				/>
			</div>
			{renderSettings()}
		</>
	);
}
