import React from 'react';
import utils from '../../../../utils';
import SubpanelTableRow from './SubpanelTableRow';
import useAction from '../../../../store/actions';

/**
 * @export
 * @param {Object} columns
 * @param {Array} data
 * @param {String} relatedModule
 * @param {Number} offset
 * @param {String} module
 * @param {Number | String} recordId
 * @param {Object} subpanels
 * @param {String} prefix
 * @param {String} subpanelKey
 * @param {Number | String} variantId
 * @param {Array} disableRowActions
 *
 * @return {JSX}
 */
export default function SubpanelTable({
	columns,
	data,
	relatedModule,
	offset,
	module,
	recordId,
	subpanels,
	prefix,
	subpanelKey,
	variantId,
	disableRowActions,
}) {
	const sAction = useAction();

	/**
	 * @return {JSX}
	 */
	const renderHeader = () => {
		return columns?.map((column) => {
			return (
				<td className="subpanelTable__headerCell" width={column.width} key={column.columnName}>
					{utils.translate(column.label)}
				</td>
			);
		});
	};

	/**
	 * @param {Number | String}
	 *
	 * @return {void}
	 */
	const removeRecordFromStore = (id) => {
		const newData = data.filter(row => row.id !== id);
		const updatedData = subpanels;
		updatedData[subpanelKey].data = newData;

		if (!variantId) {
			// standard subpanel
			sAction.initSubpanels(updatedData, prefix);
		} else {
			// variants
			sAction.dsSet(`${prefix}/data`, newData);
		}
	};

	/**
	 * @param {Number | String}
	 *
	 * @return {void}
	 */
	const deleteRelation = (id) => {
		const params = {
			module,
			relatedModule,
			recordId,
			relatedRecordId: id,
		};
		if (variantId) {
			params.variantId = variantId;
		}
		removeRecordFromStore(id);
		utils.post('deleteRelation', params).then((res) => {
			if (res.data == 0) {
				sAction.errorPopup(res.dataResult);
			}
		}).catch((error) => {
			console.error(error);
		});
	};

	/**
	 * @param {Number | String}
	 *
	 * @return {void}
	 */
	const deleteRecord = (id) => {
		removeRecordFromStore(id);
		utils.post('deleteRecords', {module: relatedModule, records: [id]})
			.then((res) => {
				if (res.data == 0) {
					sAction.errorPopup(res.dataResult);
				}
			}).catch((error) => {
				console.error(error);
			});
	};

	/**
	 * @return {JSX}
	 */
	const renderRows = () => {
		return data.map((row, i) => {
			if (i >= offset && i < offset + 10) {
				return <SubpanelTableRow
					relatedModule={relatedModule}
					columns={columns}
					row={row}
					key={row.id}
					deleteRelation={deleteRelation}
					deleteRecord={deleteRecord}
					parentId={recordId}
					variantId={variantId}
					disableRowActions={disableRowActions}
					prefix={prefix}
					subpanelKey={subpanelKey} />;
			}
		});
	};

	return (
		<div className="subpanelTable__wrapper">
			<table className="subpanelTable">
				<thead className="subpanelTable__header">
					<tr>
						{renderHeader()}
					</tr>
				</thead>
				<tbody>
					{renderRows()}
				</tbody>
			</table>
		</div>
	);
}
