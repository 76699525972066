/* eslint-disable no-undef */
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import HamburgerMenu from './HamburgerMenu';
import '../../styles/mainPage/mainPage.scss';
import utils from '../../utils';
import ReCAPTCHA from 'react-google-recaptcha';
import cz from './Languages/cz';
import en from './Languages/en';
import { Checkbox } from '@mui/material';

/**
 * @returns {jsx} jsx
 */
export default function MainPage() {
	const [openMenu, setOpenMenu] = useState(false);
	const [state, setState] = useState({});
	const [error, setError] = useState({});
	const [formError, setFormError] = useState('');
	const [formMessage, setFormMessage] = useState('');
	const [selectedPackage, setSelectedPackage] = useState('standard');
	const [lang, setLang] = useState('cz');
	const startLink = useRef(null);
	const aboutLink = useRef(null);
	const partnersLink = useRef(null);
	const productLink = useRef(null);
	const formLink = useRef(null);
	const backendUrl = process.env.REACT_APP_REST_URL;
	const recaptcha = useRef();

	const requiredFields = ['firstName', 'lastName', 'email', 'conditions'];

	let locale = cz;

	if (lang === 'en') {
		locale = en;
	}

	useEffect(() => {
		setLang(localStorage.getItem('mainPageLang') || 'cz');
	}, []);

	console.log(state.conditions);

	/**
	 *
	 *
	 * @param {string} name
	 * @param {*} value
	 */
	const setValue = (name, value) => {
		if (requiredFields.includes(name) && !value) {
			setError({...error, [name]: true});
		} else {
			setError({...error, [name]: false});
		}

		setState({...state, [name]: value});
	};

	/**
	 * Check any error in form on input blur
	 *
	 * @param {string} name
	 * @param {*} value
	 */
	const checkErrorOnBlur = (name, value) => {
		// Validate email and value in input on blur
		if (((requiredFields.includes(name) && !value) || (name === 'email' && !utils.validateEmail(value))) && !error[name]) {
			setError({...error, [name]: true});
		}
	};

	/**
	 * This function sends contact form
	 */
	const sendContactForm = () => {
		const captchaValue = recaptcha.current.getValue();
		if (!captchaValue) {
			return alert('Prosím ověřtě že nejste robot!');
		}

		let allReqFieldsFilled = true;
		const setErrors = {};
		requiredFields.forEach((requiredField) => {
			if (!state[requiredField]) {
				allReqFieldsFilled = false;
				setErrors[requiredField] = true;
			}
		});

		if (!allReqFieldsFilled) {
			setError(setErrors);
			return alert('Prosím vyplňtě všechna povinná pole!');
		}

		if (!utils.validateEmail(state.email)) {
			return alert('Prosím vyplňtě platný email!');
		}

		axios({
			method: 'POST',
			withCredentials: true,
			data: {...state, package: selectedPackage},
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			baseURL: `${backendUrl}/index.php?org_url=${window.location.pathname}&act=sendOffertoLead`,
		})
			.then((res) => {
				if (res.data === 0) { // error
					setFormError('Nepodařilo se odeslat email pro kontaktní osobu. Prosím kontaktujte nás na emailu info@rolline.cz děkujeme.');
					setFormMessage('');
				} else { // success
					setState({});
					setFormError('');
					setFormMessage('Odeslání formuláře bylo úspěšné');
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	/**
	 *
	 *
	 * @param {event} e
	 */
	const scrollToStart = (e) => {
		e.preventDefault();
		if (startLink.current) {
			window.scrollTo({
				top: startLink.current.getBoundingClientRect().top + window.pageYOffset - 300,
				behavior: 'smooth'
			});
		}
	};

	/**
	 *
	 *
	 * @param {event} e
	 */
	const scrollToAbout = (e) => {
		e.preventDefault();
		if (aboutLink.current) {
			window.scrollTo({
				top: aboutLink.current.getBoundingClientRect().top + window.pageYOffset - 300,
				behavior: 'smooth'
			});
		}
	};

	/**
	 *
	 *
	 * @param {event} e
	 */
	const scrollToPartners = (e) => {
		e.preventDefault();
		if (partnersLink.current) {
			window.scrollTo({
				top: partnersLink.current.getBoundingClientRect().top + window.pageYOffset - 300,
				behavior: 'smooth'
			});
		}
	};

	/**
	 *
	 *
	 * @param {event} e
	 */
	const scrollToProduct = (e) => {
		e.preventDefault();
		if (productLink.current) {
			window.scrollTo({
				top: productLink.current.getBoundingClientRect().top + window.pageYOffset - 300,
				behavior: 'smooth'
			});
		}
	};

	/**
	 *
	 *
	 * @param {event} e
	 */
	const scrollToForm = (e) => {
		e.preventDefault();
		if (formLink.current) {
			window.scrollTo({
				top: formLink.current.getBoundingClientRect().top + window.pageYOffset - 300,
				behavior: 'smooth'
			});
		}
	};

	/**
	 *
	 *
	 * @param {string} language
	 */
	const selectLanguage = (language) => {
		setLang(language);
		localStorage.setItem('mainPageLang', language);
	};

	return (
		<div className="mainPage">
			<nav className="mainPage__nav">
				<img onClick={scrollToStart} className="mainPage__logo" src="/images/logo2_dark.png" />
				<div className="mainPage__menu">
					<a href="#" onClick={scrollToProduct}>{locale.nav.product}</a>
					<a href="#" onClick={scrollToAbout}>{locale.nav.about}</a>
					<a href="#" onClick={scrollToPartners}>{locale.nav.partners}</a>
					<a className="mainPage__button" href="#" onClick={scrollToForm}>{locale.nav.offerto}</a>
					<div className="mainPage__langs">
						<div className={`mainPage__langs-item ${lang === 'cz' ? 'active' : ''}`} onClick={() => selectLanguage('cz')}>CZ</div>
						<div className="mainPage__langs-divider" />
						<div className={`mainPage__langs-item ${lang === 'en' ? 'active' : ''}`} onClick={() => selectLanguage('en')}>EN</div>
					</div>
				</div>
				<div className="iconfas-menu mainPage__hamburger" onClick={() => setOpenMenu(true)} />
				{openMenu ?
					<HamburgerMenu
						scrollToForm={scrollToForm}
						scrollToProduct={scrollToProduct}
						setOpenMenu={setOpenMenu}
						scrollToAbout={scrollToAbout}
						scrollToPartners={scrollToPartners}
						selectLanguage={selectLanguage}
						lang={lang}
						locale={locale}
					/>
					: null}
			</nav>
			<main ref={startLink} className="mainPage__home">
				<div className="mainPage__homeBox">
					<div className="mainPage__homeBox-box">
						<h1 className="mainPage__header-big">{locale.main.header}</h1>
						<a href="#" onClick={scrollToForm} className="mainPage__buttonLight">{locale.main.button}</a>
					</div>
					<div className="mainPage__appScreenBox">
						<img src="/images/app_screen_1.png" />
					</div>
				</div>
			</main>
			<div className="pageInfo" ref={productLink}>
				<h1 className="pageInfo__header">{locale.about.header}</h1>
				<div className="pageInfo__box">
					<div className="pageInfo__content">
						<div className="pageInfo__textBox">
							<h4>{locale.about.firstSectionHeader}</h4>
							<p>{locale.about.firstSectionText}</p>
						</div>
						<div className="pageInfo__textBox">
							<h4>{locale.about.secondSectionHeader}</h4>
							<p>{locale.about.secondSectionText}</p>
						</div>
						<div className="pageInfo__textBox">
							<h4>{locale.about.thirdSectionHeader}</h4>
							<p>{locale.about.thirdSectionText}</p>
						</div>
						<div className="pageInfo__textBox">
							<h4>{locale.about.fourthSectionHeader}</h4>
							<p>{locale.about.fourthSectionText}</p>
						</div>
						<div className="pageInfo__textBox">
							<h4>{locale.about.fifthSectionHeader}</h4>
							<p>{locale.about.fifthSectionText}</p>
						</div>
						<div className="pageInfo__textBox">
							<h4>{locale.about.sixthSectionHeader}</h4>
							<p>{locale.about.sixthSectionText}</p>
						</div>
					</div>
					<img src="/images/app_screen_2.png"/>
				</div>
			</div>
			<div className="solution">
				<div className="solution__box">
					<h1>{locale.solution.header}</h1>
					<p>{locale.solution.text}</p>
					<div>
						<a href="#" onClick={scrollToAbout} className="mainPage__buttonLight">{locale.solution.button}</a>
					</div>
				</div>
			</div>
			<div className="about" ref={aboutLink}>
				<h1>{locale.story.header}</h1>
				<div className="about__box">
					<div className="about__section">
						<h4>{locale.story.sections.story.header}</h4>
						<p>{locale.story.sections.story.text}</p>
					</div>
					<div className="about__section">
						<h4>{locale.story.sections.vision.header}</h4>
						<p>{locale.story.sections.vision.text}</p>
					</div>
					<div className="about__section">
						<h4>{locale.story.sections.technology.header}</h4>
						<p>{locale.story.sections.technology.text}</p>
					</div>
				</div>
			</div>
			<div className="reference" ref={partnersLink}>
				<div className="reference__header">
					<h1>{locale.reference.header}</h1>
					<div className="reference__divider"/>
					<h1 className="reference__name" onClick={() => window.open('https://www.innovasoft.cz')}>{locale.reference.companyName}</h1>
				</div>
			</div>
			<div className="mainPage__packages">
				<div className="mainPage__packages-box">
					{locale?.packages.map((item, index) => {
						return (
							<div
								className="mainPage__package"
								style={{
									background: item.bgColor,
									borderTop: `20px solid ${item.color}`,
									borderBottom: `5px solid ${item.color}`,
									transform: `${item.name === selectedPackage ? 'scale(1.04)' : ''}`,
								}}
								key={index}
							>
								<h4 style={{color: item.color}} className="mainPage__package-header">
									<span className={`${item.icon} mainPage__package-header--icon`}/>
									{item.header}
								</h4>
								<div className="mainPage__package-price">
									{item.price}
								</div>
								<ul className="mainPage__package-list">
									<li>
										<span className="iconfas-user mainPage__package-list--icon"/>
										{item.desc1}
									</li>
									<li>
										<span className="iconfas-client mainPage__package-list--icon"/>
										{item.desc2}
									</li>
									<li>
										<span className="iconfas-report mainPage__package-list--icon"/>
										{item.desc3}
									</li>
								</ul>
								{item.name === selectedPackage ? <span className="mainPage__package-selected iconfas-check" /> :
									<button
										onClick={() => setSelectedPackage(item.name)}
										style={{ border: `2px solid ${item.color}`, color: item.color}}
										className="mainPage__button2 mainPage__package-button"
									>
										{item.button}
									</button>}
							</div>
						);
					})}
				</div>
			</div>
			<div className="contact">
				<h1>{locale.contact.header}</h1>
				<div className="contact__form" ref={formLink}>
					<h4>{locale.contact.subheader}</h4>
					<div className="contact__form-box">
						<div className="contact__form-input">
							<div>
								{locale.contact.form.name}
								<span className="required">*</span>
							</div>
							<input
								onChange={(e) => setValue('firstName', e.target.value)}
								value={state.firstName || ''}
								className={error.firstName ? 'inputError' : ''}
								onBlur={(e) => checkErrorOnBlur('firstName', e.target.value)}
							/>
						</div>
						<div className="contact__form-input">
							<div>
								{locale.contact.form.lastName}
								<span className="required">*</span>
							</div>
							<input
								onChange={(e) => setValue('lastName', e.target.value)}
								value={state.lastName || ''}
								className={error.lastName ? 'inputError' : ''}
								onBlur={(e) => checkErrorOnBlur('lastName', e.target.value)}
							/>
						</div>
					</div>
					<div className="contact__form-box">
						<div className="contact__form-input">
							<div>
								{locale.contact.form.email}
								<span className="required">*</span>
							</div>
							<input
								onChange={(e) => setValue('email', e.target.value)}
								value={state.email || ''}
								className={error.email ? 'inputError' : ''}
								onBlur={(e) => checkErrorOnBlur('email', e.target.value)}
							/>
						</div>
						<div className="contact__form-input">
							<div>{locale.contact.form.phone}</div>
							<input
								onChange={(e) => setValue('phone', e.target.value)}
								value={state.phone || ''}
								type="number"
								maxLength={9}
							/>
						</div>
					</div>
					<div className="contact__form-box">
						<div className="contact__form-input">
							<div>{locale.contact.form.company}</div>
							<input
								onChange={(e) => setValue('company', e.target.value)}
								value={state.company || ''}
							/>
						</div>
						<div className="contact__form-input">
							<div>{locale.contact.form.position}</div>
							<input
								onChange={(e) => setValue('position', e.target.value)}
								value={state.position || ''}
							/>
						</div>
					</div>
					<div className="contact__form-box">
						<div className="contact__form-input">
							<span>
								<Checkbox
									className="contact__form-conditions"
									onChange={(e) => setValue('conditions', e.target.checked)}
									value={state.conditions || 0}
								/>
								{locale.contact.form.acceptConditions}
								<a href="/files/obchodni_podminky.pdf" download>
									{locale.contact.form.conditions}
								</a>
								<span className="required">*</span>
							</span>
						</div>
					</div>
					<div className="contact__form-captcha">
						<ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
					</div>
					<div className="contact__form-box">
						<button onClick={(e) => sendContactForm(e)} className="contact__form-button mainPage__button">{locale.contact.form.button}</button>
					</div>
					{formError ?
						<div className="contact__form-box contact__form-error">
							{formError}
						</div> : null}
					{formMessage ?
						<div className="contact__form-box contact__form-message">
							{formMessage}
						</div> : null}
				</div>
			</div>
			<div className="footer">
				<div className="footer__contact">
					<div>
						{locale.footer.email} info@innovasoft.cz
					</div>
					<div>
						{locale.footer.phone} +420 604 189 483
					</div>
					<div className="footer__copyright">
						<span className="fa-regular fa-copyright" />
						{locale.footer.copyright}
					</div>
				</div>
				<div className="footer__social">
					{locale.footer.social}
					<span className="fa-brands fa-facebook" />
					<span className="fa-brands fa-twitter" />
				</div>
			</div>
		</div>
	);
}
