/**
 * @export
 * @param {String} expression
 * @param {Object} variables
 * @return {Float}
 */
export default function useCalculateLines(expression, variables) {
	const func = new Function(...Object.keys(variables), `return ${expression};`);

	return func(...Object.values(variables));
}
