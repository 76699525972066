import useAction from '../../store/actions';

/**
 * @export
 * @param {String} prefix
 * @param {Object} userData
 * @param {Function} saveDetail
 *
 * @return {Function}
 */
export default function cancelQuote(prefix, userData, saveDetail) {
	const sAction = useAction();

	return () => {
		sAction.confirmPopup('LBL_CONFIRM_CANCELED', () => {
			if (userData.roleTypeId <= 10) {
				sAction.dsSet(`${prefix}/detail/fields/status/value`, 'cancelledByClient');
				saveDetail({status: 'cancelledByClient'});
			} else {
				sAction.dsSet(`${prefix}/detail/fields/status/value`, 'cancelled');
				saveDetail({status: 'cancelled'});
			}
		});
	};
}
