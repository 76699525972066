import React from 'react';
import { Popover as MuiPopover } from '@mui/material';
import utils from '../../utils';

export default function Popover({id, open, anchor, options}) {

	const renderOption = () => {
		return options?.map((item, i) => {
			return (
				<div onClick={item.onClick} key={i} className="popover__item">
					<div className={`iconfas-${item.icon} popover__item-icon`} />
					<div>{utils.translate(item.label)}</div>
				</div>
			);
		});
	};

	return (
		<MuiPopover
			id={id}
			open={open}
			anchorEl={anchor}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			className="popover">
			{renderOption()}
		</MuiPopover>
	);
}