import React, {useState, useEffect, useRef} from 'react';
import utils from '../../utils';
import useAction from '../../store/actions';
import ActionButtons from '../ActionButtons/ActionButtons';
import CalendarList from './CalendarList';
import CalendarViewSwitch from './CalendarViewSwitch';
import Loader from '../Loader/Loader';
import CalendarChangeDate from './CalendarChangeDate';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import allLocales from '@fullcalendar/core/locales-all';
import interactionPlugin from '@fullcalendar/interaction';
import CalendarEvent from './CalendarEvent';
import getFirstDateInMonth from '../../utils/date/getFirstDateInMonth';

/**
 * @export
 * @param {Object} config
 * @param {Object} calendarStore
 *
 * @return {JSX}
 */
export default function Calendar({config, calendarStore}) {
	const todayDate = new Date();
	const sAction = useAction();
	const [load, setLoad] = useState(true);
	const [calData, setCalData] = useState([]);
	const [calType, setCalType] = useState('month');
	const [timeline, setTimeline] = useState(null);
	const [date, setDate] = useState(utils.formatDateToDB(todayDate));

	const calendarRef = useRef();

	useEffect(() => {
		sAction.contentUnload();
		setDate(utils.formatDateToDB(todayDate));
	}, []);

	useEffect(() => {
		if (calendarStore.reload) {
			getCalendar(calType, date);
			sAction.dsSet('view/calendar/reload', false);
		}
	}, [calendarStore.reload]);

 	useEffect(() => {
		if (calType === 'month') {
			getCalendar(calType, getFirstDateInMonth(date));
		} else if (calType === 'week') {
			getCalendar(calType, utils.getMonday(date));
		} else if (calType === 'day') {
			getCalendar(calType, utils.formatDateToDB(new Date()));
		}
	}, [calType, date]);

	/**
 	 * @param {String} type
 	 * @param {Date} date
 	 *
 	 * @return {void}
 	 */
	const getCalendar = (type, date) => {
		setLoad(true);
		utils
			.post('getCalendar', {type, date})
			.then((res) => {
				setCalData(res.data.data);
				setTimeline(res.data.timeline);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(false);
				console.error(error);
			});
	};

	/**
	 * Function is called on event drop in calendar
	 * It saves the record with new date
	 *
 	 * @param {Object} data
 	 *
 	 * @return {Array}
 	 */
	const handleEventDrop = (data) => {
		const start = data.event._instance.range.start;
		const end = data.event._instance.range.end;
		const extendedProps = data.event._def.extendedProps;
		const originalEvent = { ...data.event._instance };

		const newStartDate = new Date(start);
		newStartDate.setHours(originalEvent.range.start.getHours(), originalEvent.range.start.getMinutes(), 0);

		const newEndDate = new Date(end);
		newEndDate.setHours(originalEvent.range.end.getHours(), originalEvent.range.end.getMinutes(), 0);

		utils.post('saveDetail', {fromDT: start, toDT: end, module: 'event', recordId: extendedProps.recordId})
			.then((res) => {
				if (res.data == 0) {
					data.revert(); // Revert dates if the save fails
					sAction.errorPopup(res.dataResult);
				}
			}).catch((error) => {
				console.error(error);
			});
	};

	/**
	 * Events initialization
	 *
 	 * @return {Array}
 	 */
	const initEvents = () => {
		const events = [];
		calData.forEach((event, i) => {
			const color = timeline?.status.values[event.status];
			const pushEvent = {
				title: event.name,
				recordId: event.id,
				allDay: false,
				start: event.fromDT,
				end: event.toDT,
				note: event.note,
				backgroundColor: color,
				borderColor: color,
			};

			events.push(pushEvent);
		});

		return events;
	};

	/**
	 * Event component that is rendered in calendar
	 *
	 * @param {Object} info
	 *
 	 * @return {JSX}
 	 */
	const eventContent = (info) => {
		return <CalendarEvent info={info} getCalendar={getCalendar} date={date} calType={calType} />;
	};

	/**
	 * @param {Object} arg
	 *
 	 * @return {JSX}
 	 */
	const dayCellContent = (arg) => {
		const dayNumber = arg.dayNumberText.toString().replace('.', '');
		return <span>{dayNumber}</span>;
	};

	// Data for calendar
	const language = sAction.dsGet('user/language');
	let calendarView = 'timeGridWeek';
	let slotDuration = '00:30:00';
	if (calType === 'week') {
		calendarView = 'timeGridWeek';
	} else if (calType === 'day') {
		calendarView = 'timeGridDay';
	} else {
		calendarView = 'dayGridMonth';
	}
	const locale = language === 'cz' ? 'cs' : language;

	return (
		<>
			<div className="actionBox calendar__actionBox">
				<ActionButtons>
					<CalendarViewSwitch calType={calType} setCalType={setCalType} calendarRef={calendarRef} />
				</ActionButtons>
				<CalendarChangeDate calendarRef={calendarRef} setDate={setDate} date={date} calType={calType} />
			</div>
			<div className="calendarContent" >
				<div className={`calendar ${load ? 'calendar__load' : ''}`}>
					{load && !config.load ? <Loader transparent={true} /> : null}
					<FullCalendar
						plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
						views={['timeGridWeek', 'timeGridDay', 'dayGridMonth']}
						initialView={calendarView}
						height={'100%'}
						ref={calendarRef}
						slotDuration={slotDuration}
						headerToolbar={false}
						locale={locale}
						locales={allLocales}
						weekNumbers
						editable
						events={initEvents()}
						initialEvents={initEvents()}
						displayEventEnd
						dayMaxEventRows={8}
						eventDisplay="block"
						eventContent={eventContent}
						contentHeight={1}
						expandRows={false}
						weekText=""
						dayCellContent={dayCellContent}
						eventDrop={handleEventDrop}
						eventDurationEditable={false}
						dateClick={(data) => {
							const increasedDate = new Date(data.date);
							// Increase to date by 1 hour
							const setFields = {
								toDT: utils.formatDateToDB(utils.formatDateToDB(increasedDate.setHours(increasedDate.getHours() + 1))),
								fromDT: utils.formatDateToDB(data.date),
							};
							sAction.setPredefinedFields(setFields);

							sAction.openPopup('DetailFormPopup', 'LBL_EVENT', false, {module: 'event', recordId: 'newRecord'}, () => {
								getCalendar(calType, date);
							});
						}}
					/>
				</div>
				<CalendarList getCalendar={getCalendar} calType={calType} locale={locale} events={initEvents()} date={date} />
			</div>
		</>
	);
}