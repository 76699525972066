import utils from '..';

/**
 * @export
 * @param {String | Number} ico
 *
 * @return {Boolean}
 */
export default function validateIco(ico) {
	if (ico.length !== 8 || !/^\d+$/.test(ico)) {
		return false;
	}

	let icoNumbers = ico.split('').map(Number);

	let weights = [8, 7, 6, 5, 4, 3, 2];

	let checkSum = icoNumbers.slice(0, 7).reduce((sum, digit, index) => sum + digit * weights[index], 0);

	let checkNumber = checkSum % 11;

	if (checkNumber === 0) {
		checkNumber = 1;
	} else if (checkNumber === 1) {
		checkNumber = 0;
	} else {
		checkNumber = 11 - checkNumber;
	}

	return icoNumbers[7] === checkNumber;
}
