/**
 * @export
 * @param {Object} def
 * @param {Object} fields
 * @param {Object} buttons
 * @param {Object} timeline
 * @param {Object} subpanels
 * @param {String} prefix
 *
 * @return {Function}
 */
export default function initData(fields, buttons, timeline, subpanels = [], extraData = {}, prefix) {
	return (dispatch) => {
		dispatch({
			type: 'INIT_DETAIL',
			payload: {fields, buttons, timeline, subpanels, extraData, prefix},
		});
	};
}
