import useAction from '../../store/actions';

/**
 * @export
 * @param {String} prefix
 * @param {Function} saveDetail
 *
 * @return {Function}
 */
export default function approveQuote(prefix, saveDetail) {
	const sAction = useAction();

	return () => {
		sAction.confirmPopup('LBL_CONFIRM_APPROVED', () => {
			sAction.dsSet(`${prefix}/detail/fields/status/value`, 'approved');
			saveDetail({status: 'approved'});
		});
	};
}
