/**
 * @export
 * @param {Object} state
 * @param {String} action
 * @return {Object}
 */
export default function hideInfoBanner(state) {
	return { ...state, config: {
		...state.config,
		infoBanner: {
			message: '',
			icon: '',
			type: '',
		}
	}
	};
}